import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { UrlHelper } from "../../../../react-components/urlUtils";
import { ComponentProps } from "../../../../Partials/ComponentProps.csharp";
import { DiscoveryTabsProps } from "./DiscoveryTabsProps.csharp";
import { Tab } from "./Tab.csharp";
import classNames from "classnames";
import { ParamType, useSearchParams } from "../../../../react-components/useSearchParams";

const jobIdSearchParam = "jobToBeDoneId";

const DiscoveryTabs: FC<ComponentProps<DiscoveryTabsProps>> = ({ model: { tabs } }) => {
  const [chosenTab, setChosenSections] = useState<Tab>(tabs[0]);
  const sectionsRef = useRef<(Element | null)[]>();
  const labelsRef = useRef<HTMLDivElement>(null);
  const [indicatorPosition, setIndicatorPosition] = useState({ width: 0, left: 0 });
  const useSearch = useSearchParams({
    [jobIdSearchParam]: ParamType.String,
  });

  const handleTabSelection = (tab: Tab) => {
    setChosenSections(tab);
    useSearch.setParams({ jobToBeDoneId: tab.id });
    UrlHelper.removeHashFromUrl();
  };

  const handleLabelMouseover: EventListener = (event) => {
    const target = event.target;
    if (target) {
      const targetRects = (target as HTMLButtonElement).getBoundingClientRect();
      const labelsRects = labelsRef.current?.getBoundingClientRect();
      const isMobile = !window.matchMedia("(min-width: 1440px"); // matches css media query
      const width = Math.max(targetRects.width, isMobile ? 198 : 0); // max label width
      const left = targetRects.x - (labelsRects?.x || 0);
      setIndicatorPosition({ width, left });
    }
  };

  useEffect(() => {
    const tabId = useSearch.params[jobIdSearchParam];
    const selectedTab = tabs.find((tab) => tab.id === tabId);
    if (selectedTab) setChosenSections(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sectionsRef.current = tabs.map((x) => document.querySelector(`#${x.sectionId}`));
  }, [tabs]);

  useEffect(() => {
    let labels: RefObject<HTMLDivElement>["current"];
    if (labelsRef.current) {
      labels = labelsRef.current;
      labels.childNodes.forEach((label) => {
        label.addEventListener("mouseover", handleLabelMouseover);
      });
    }

    return () => {
      if (labels) {
        labels?.childNodes.forEach((label) =>
          label.removeEventListener("mouseover", handleLabelMouseover),
        );
      }
    };
  }, []);

  useEffect(() => {
    if (!sectionsRef.current) return;

    for (const section of sectionsRef.current) {
      if (!section) continue;

      if (section.id === chosenTab.sectionId) {
        section?.classList.add("JobsToBeDone--active");
      } else {
        section?.classList.remove("JobsToBeDone--active");
      }
    }
  }, [chosenTab]);

  return (
    <div className="JobsTabs__tabsWrapper">
      <div
        className="JobsTabs__tabs"
        role="tablist"
        aria-orientation="horizontal"
        aria-label="Open vessel listing divided by segment name or by first letter of vessel name"
        ref={labelsRef}
      >
        {tabs.map((t) => (
          <button
            key={t.id}
            id={t.id}
            className={classNames("JobsTabs__tab", {
              ["JobsTabs__tab--active"]: t.id === chosenTab.id,
            })}
            type="button"
            role="tab"
            aria-selected={t.id === chosenTab.id}
            aria-controls={t.sectionId}
            onClick={() => handleTabSelection(t)}
          >
            <span>{t.name}</span>
          </button>
        ))}
        <div
          className="JobsTabs__hoverIndicator"
          aria-hidden="true"
          style={{
            width: `${indicatorPosition.width}px`,
            transform: `translateX(${indicatorPosition.left}px)`,
          }}
        />
      </div>
    </div>
  );
};

export { DiscoveryTabs };
