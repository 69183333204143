import { QueryFunctionContext } from "@tanstack/react-query";
import { PagingModel } from "../../Common/PagingModel.csharp";
import { APIClient } from "../../react-components/api/APIClient";
import { UrlHelper } from "../../react-components/urlUtils";
import { ResourceViewModel } from "./ViewModels/ResourceViewModel.csharp";

export type ResourcesQueryKey = [
  string,
  {
    selectedCategories: string[] | undefined;
    selectedTopics: string[] | undefined;
  },
];

export type ResourceQueryFunctionContext = QueryFunctionContext<ResourcesQueryKey, number>;

export class ResourcesAPIClient extends APIClient {
  private endpoint: URL | null;

  constructor(endpoint: string) {
    super();

    this.endpoint = UrlHelper.constructUrl(endpoint);
  }

  public getResources({ queryKey, pageParam }: ResourceQueryFunctionContext) {
    if (!this.endpoint) return null;

    const [, { selectedCategories, selectedTopics }] = queryKey;
    const endpoint = this.buildQuery(this.endpoint, pageParam, selectedCategories, selectedTopics);

    return this.get<PagingModel<ResourceViewModel>>(endpoint);
  }

  private buildQuery(endpoint: URL, page?: number, categories?: string[], topics?: string[]) {
    const url = new URL(endpoint);

    if (page !== undefined) {
      url.searchParams.set("page", `${page}`);
    }

    if (categories?.length) {
      categories.forEach((category) => {
        url.searchParams.append("category", category);
      });
    }

    if (topics?.length) {
      topics.forEach((topic) => {
        url.searchParams.append("topic", topic);
      });
    }

    return url;
  }
}
