import React, { FC, useState } from "react";
import { ImageViewerMainImage } from "./ImageViewerMainImage";
import { ImageViewerProps } from "./ImageViewerProps.csharp";
import { ImageViewerArrow } from "./ImageViewerArrow";
import { ImageViewerThumbnails } from "./ImageViewerThumbnails";

export const ImageViewer: FC<ImageViewerProps> = ({
  model: { images },
  thumbnailProfile,
  imageViewerVariant,
  mainProfile,
}) => {
  const [activeImageIdx, setActiveImageIdx] = useState<number>(0);

  const onThumbnailClick = (idx: number) => {
    setActiveImageIdx(idx);
  };

  const nextImage = () => {
    setActiveImageIdx((prevState) => {
      return prevState !== images.length - 1 ? prevState + 1 : 0;
    });
  };

  const prevImage = () => {
    setActiveImageIdx((prevState) => {
      return prevState !== 0 ? prevState - 1 : images.length - 1;
    });
  };

  return (
    <>
      {images && images.length > 0 && (
        <div className="ImageViewer">
          <ImageViewerMainImage
            model={images}
            profile={mainProfile}
            activeImageIndex={activeImageIdx}
            variant={imageViewerVariant}
          />
          <div className="ImageViewer__mainImageArrowsWrapper">
            <ImageViewerArrow direction="left" onMainImage onClick={prevImage} />
            <ImageViewerArrow direction="right" onMainImage onClick={nextImage} />
          </div>

          <ImageViewerThumbnails
            thumbnailProfile={thumbnailProfile}
            onThumbnailClick={onThumbnailClick}
            activeImageIdx={activeImageIdx}
            images={images}
            prevImage={prevImage}
            nextImage={nextImage}
          />
        </div>
      )}
    </>
  );
};
