import React, { FC, useEffect, useState } from "react";
import { ComponentProps } from "../../ComponentProps.csharp";
import { ContentListTilesViewModel } from "../Tiles/ContentListTilesViewModel.csharp";
import { ContentListSlider } from "../Slider/ContentListSlider";
import { InfoMosaic } from "../../InfoMosaic/InfoMosaic";
import classNames from "classnames";
import { InfoRevealTile } from "../../InfoRevealTile/InfoRevealTile";
import { shuffle } from "lodash";
import { SplideSlide } from "@splidejs/react-splide";
import { ButtonFill } from "../../../react-components/Buttons/ButtonFill";
import { ContentListTileViewModel } from "./ContentListTileViewModel.csharp";

export const ContentListTilesBlock: FC<ComponentProps<ContentListTilesViewModel>> = ({ model }) => {
  const { title, description, contentListTiles, randomizeContent, cta } = model;
  const maxItemsToShow = 5;

  const [selectedTiles, setSelectedTiles] = useState<ContentListTileViewModel[]>();

  useEffect(() => {
    setSelectedTiles(
      (randomizeContent ? shuffle(contentListTiles) : contentListTiles).slice(0, maxItemsToShow),
    );
  }, [contentListTiles, randomizeContent]);

  if (!selectedTiles || !selectedTiles.length) return null;

  const renderSliderContent = () => {
    return selectedTiles.map((item, idx) => {
      return (
        <SplideSlide
          className="splide__slide ContentListTilesBlock__slide ContentListTilesBlock__slide--withBg"
          key={idx}
        >
          <div
            className={classNames(
              "ContentListTilesBlock__slidesBgWrapper",
              `ContentListTilesBlock__slidesBgWrapper--length${selectedTiles.length}`,
              `ContentListTilesBlock__slidesBgWrapper--nr${idx + 1}`,
            )}
          >
            <img
              src={item.image.model.url}
              alt=""
              className="ContentListTilesBlock__slidesBg"
              aria-hidden
            />
          </div>

          <InfoRevealTile model={item} />
        </SplideSlide>
      );
    });
  };

  return (
    <div className="ContentListTilesBlock">
      <div className="ContentListTilesBlock__mobile">
        <ContentListSlider
          className="ContentListTilesBlock__slider"
          title={title}
          description={description}
        >
          {renderSliderContent()}
        </ContentListSlider>
      </div>
      <div className="ContentListTilesBlock__desktop">
        <InfoMosaic title={title} description={description} tiles={selectedTiles} />
      </div>
      <div className="wrapper ContentListTilesBlock__cta">
        {cta.href && cta.text && (
          <ButtonFill as="a" color="teal" target={cta.target ?? "_blank"} href={cta.href}>
            {cta.text}
          </ButtonFill>
        )}
      </div>
    </div>
  );
};
