import React, { FC, useEffect, useRef, useState } from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { SpecificationsAndDownloadSectionViewModel } from "../SpecificationsAndDownloadsSection/SpecificationsAndDownloadSectionViewModel.csharp";
import { flattenDeep, throttle } from "lodash";
import { SpecificationViewModel } from "../../../Partials/TechnicalSpecifications/SpecificationViewModel.csharp";
import { ProductRangeSpecificationsViewModel } from "../SpecificationsAndDownloadsSection/ProductRangeSpecificationsViewModel.csharp";
import { Tooltip } from "../../../react-components/Discovery/Tooltip/Tooltip";
import { TooltipTrigger } from "../../../react-components/Discovery/Tooltip/TooltipTrigger";
import { TooltipContent } from "../../../react-components/Discovery/Tooltip/TooltipContent";
import { Info as InfoIcon } from "../../../react-components/Discovery/Icons/Info";
import { ButtonGhost } from "../../../react-components/Buttons/ButtonGhost";
import { Chevron } from "../../../react-components/Icons/Chevron";
import classNames from "classnames";

const formatSpecificationValue = (specification: SpecificationViewModel): string => {
  const unit = specification.unit ? ` ${specification.unit}` : "";
  const customUnit = specification.customUnit ? ` ${specification.customUnit}` : "";
  return `${specification.value}${unit}${customUnit}`;
};

const findSpecificationValuesAndTooltipByTitle = (
  products: ProductRangeSpecificationsViewModel[],
  title: string,
): { values: string[]; tooltip?: string } => {
  const foundSpecifications: string[] = [];
  let foundTooltip = "";
  products.forEach((product) => {
    let foundSpecificationInProduct: SpecificationViewModel | null = null;
    if (product.technicalSpecifications?.specificationGroups?.length) {
      for (const group of product.technicalSpecifications.specificationGroups) {
        const foundSpecification = group.specifications.find(
          (specification) => specification.title === title,
        );
        if (foundSpecification) {
          foundSpecificationInProduct = foundSpecification;
          break;
        }
      }
    }
    foundSpecifications.push(
      foundSpecificationInProduct ? formatSpecificationValue(foundSpecificationInProduct) : "",
    );
    if (!foundTooltip && foundSpecificationInProduct?.tooltip) {
      foundTooltip = foundSpecificationInProduct.tooltip;
    }
  });
  return {
    values: foundSpecifications,
    tooltip: foundTooltip,
  };
};

const prepareRows = (
  products: ProductRangeSpecificationsViewModel[],
): Record<string, { values: string[]; tooltip?: string }> => {
  const parameters = products.map((product) =>
    product.technicalSpecifications.specificationGroups.map((specificationGroup) =>
      specificationGroup.specifications.map((specification) => specification.title),
    ),
  );
  const parametersFlattened = flattenDeep(parameters);
  const parametersWithoutDuplicates = Array.from(new Set(parametersFlattened));

  const parametersWithData = parametersWithoutDuplicates.reduce(
    (acc: Record<string, { values: string[]; tooltip?: string }>, cur: string) => {
      const foundSpecificationValuesAndTooltip = findSpecificationValuesAndTooltipByTitle(
        products,
        cur,
      );
      acc[cur] = foundSpecificationValuesAndTooltip;
      return acc;
    },
    {},
  );

  return parametersWithData;
};

export const ComparisonBlock: FC<ComponentProps<SpecificationsAndDownloadSectionViewModel>> = ({
  model: {
    comparisonSectionViewModel: { title, productRangeSpecifications },
  },
}) => {
  const scrollableElRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [reachedStart, setReachedStart] = useState<boolean>(true);
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);
  const [moveButtonStyles, setMoveButtonStyles] = useState<{ top: number; height: number }>({
    top: 48,
    height: 100,
  });

  const productNames = productRangeSpecifications.map((product) => product.productName);
  const rows = prepareRows(productRangeSpecifications);

  const handleButtonMoveClick = (direction: "left" | "right") => {
    if (!scrollableElRef.current) return;
    const { clientWidth } = scrollableElRef.current;
    if (direction === "right") {
      scrollableElRef.current.scrollLeft += clientWidth * 0.8;
    } else {
      scrollableElRef.current.scrollLeft -= clientWidth * 0.8;
    }
  };

  const checkScrollPosition = () => {
    if (!scrollableElRef.current) return;
    const { clientWidth, scrollWidth, scrollLeft } = scrollableElRef.current;
    setReachedStart(scrollLeft < 10);
    setReachedEnd(scrollWidth - 10 < clientWidth + scrollLeft);
  };

  const checkIfIsOverflowing = () => {
    if (!scrollableElRef.current) return;
    const hasOverflow = scrollableElRef.current.scrollWidth > scrollableElRef.current.clientWidth;
    setIsOverflowing(hasOverflow);
  };

  const calculateMoveButtonPosition = () => {
    setMoveButtonStyles({
      height: tableBodyRef.current?.clientHeight || 100,
      top: tableHeadRef.current?.clientHeight || 48,
    });
  };

  const handleWindowResize = () => {
    checkScrollPosition();
    checkIfIsOverflowing();
    calculateMoveButtonPosition();
  };

  useEffect(() => {
    const scrollableEl = scrollableElRef.current;
    if (typeof window === "undefined" || !scrollableEl) return;
    handleWindowResize();
    window.addEventListener("resize", throttle(handleWindowResize, 200));
    scrollableEl.addEventListener("scroll", throttle(checkScrollPosition, 200));
    return () => {
      window.removeEventListener("resize", throttle(handleWindowResize, 200));
      if (scrollableEl) {
        scrollableEl.removeEventListener("scroll", throttle(checkScrollPosition, 200));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("ComparisonBlock", {
        "ComparisonBlock--sidePaddings": isOverflowing,
      })}
    >
      <div className="wrapper">
        <h2 className="Section__title">{title}</h2>
        <div className="ComparisonBlock__contentWrapper">
          <ButtonGhost
            icon={Chevron}
            type="icon"
            aria-label="Scroll table to the left"
            className={classNames("ComparisonBlock__moveBtn", "ComparisonBlock__moveBtn--left", {
              "ComparisonBlock__moveBtn--hidden": !isOverflowing,
            })}
            onClick={() => !reachedStart && handleButtonMoveClick("left")}
            aria-disabled={reachedStart}
            style={moveButtonStyles}
          />
          <div className="ComparisonBlock__content" ref={scrollableElRef}>
            <table className="ComparisonBlock__table">
              <caption className="visuallyHidden">Comparison</caption>
              <thead ref={tableHeadRef}>
                <tr>
                  <th scope="col">
                    <span className="visuallyHidden">Specification</span>
                  </th>
                  {productNames.map((productName, idx) => (
                    <th key={idx} scope="col" className="ComparisonBlock__productNameDrawer">
                      {productName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody ref={tableBodyRef}>
                {Object.entries(rows).map(([specification, { values, tooltip }], idx) => (
                  <tr key={idx} className="ComparisonBlock__specRow">
                    <th scope="row" className="ComparisonBlock__specNameDrawer">
                      <div className="ComparisonBlock__specNameDrawerContent">
                        <span className="ComparisonBlock__specName">{specification}</span>
                        {tooltip && (
                          <Tooltip>
                            <TooltipTrigger className="ComparisonBlock__tooltipTrigger">
                              <InfoIcon className="ComparisonBlock__tooltipTriggerIcon" />
                            </TooltipTrigger>
                            <TooltipContent
                              maxWidth={164}
                              className="ComparisonBlock__tooltipContent"
                            >
                              {tooltip}
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </div>
                    </th>
                    {values.map((value, idx) => (
                      <td key={idx} className="ComparisonBlock__specValueDrawer">
                        {value ? (
                          value
                        ) : (
                          <>
                            <span aria-hidden>-</span>
                            <span className="visuallyHidden">not applicable or no data</span>
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ButtonGhost
            icon={Chevron}
            type="icon"
            aria-label="Scroll table to the right"
            className={classNames("ComparisonBlock__moveBtn", "ComparisonBlock__moveBtn--right", {
              "ComparisonBlock__moveBtn--hidden": !isOverflowing,
            })}
            onClick={() => !reachedEnd && handleButtonMoveClick("right")}
            aria-disabled={reachedEnd}
            style={{
              height: tableBodyRef.current?.clientHeight,
              top: tableHeadRef.current?.clientHeight,
            }}
          />
        </div>
      </div>
    </div>
  );
};
