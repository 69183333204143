import React, { FC, useEffect, useRef, useState } from "react";
import { ComponentProps } from "../../../../Partials/ComponentProps.csharp";
import { DiscoveriesListingProps } from "./DiscoveriesListingProps.csharp";
import { TileGrid } from "../../../../react-components/TileGrid/TileGrid";
import { HeroTile } from "../../../../react-components/TileGrid/Tiles/HeroTile/HeroTile";
import { DiscoveriesList } from "./DiscoveriesList/DiscoveriesList";
import { DiscoveryListingItemProps } from "./DiscoveryListingItemProps.csharp";
import { ButtonFill } from "../../../../react-components/Buttons/ButtonFill";

const initialPage = 1;
const pageSize = 3;
const initialSize = 6;

const getAllJobsList = (discoveries: DiscoveryListingItemProps[]) =>
  discoveries.map((discovery) => discovery.jobsToBeDone).flat();

export const DiscoveriesListing: FC<ComponentProps<DiscoveriesListingProps>> = ({
  model: { discoveries, discoveriesListTranslations },
}) => {
  const [selectedDiscovery, setSelectedDiscovery] = useState<DiscoveryListingItemProps | null>(
    null,
  );
  const [filteredJobs, setFilteredJobs] = useState(getAllJobsList(discoveries));
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [visibleJobs, setVisibleJobs] = useState(filteredJobs.slice(0, initialSize));
  const [focusTile, setFocusTile] = useState(0);
  const newTileRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    newTileRef.current?.focus();
  }, [visibleJobs.length]);

  const filterJobs = (id: string | null) => {
    setCurrentPage(initialPage);
    const newSize = initialSize;
    if (id === null) {
      const newDiscoveries = getAllJobsList(discoveries);
      const newVisibleJobs = newDiscoveries.slice(0, newSize);

      setSelectedDiscovery(id);
      setFilteredJobs(newDiscoveries);
      setVisibleJobs(newVisibleJobs);
      return;
    }

    const filteredList = discoveries.filter((discovery) => discovery.id === id);
    const filteredJobsList = getAllJobsList(filteredList);
    const discovery = discoveries.find((discovery) => discovery.id === id) || null;
    setSelectedDiscovery(discovery);
    setFilteredJobs([...filteredJobsList]);
    setVisibleJobs(filteredJobsList.slice(0, newSize));
  };

  const handleShowMore = () => {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    setFocusTile(newCurrentPage * pageSize);
    setVisibleJobs(filteredJobs.slice(0, (newCurrentPage - 1) * pageSize + initialSize));
  };

  return (
    <TileGrid
      mainContent={
        <>
          <ul className="DiscoveriesListing__jobList">
            {visibleJobs.map((job, i) => (
              <li key={job.id}>
                <HeroTile
                  title={job.name}
                  href={job.url}
                  picture={job.picture}
                  description={job.description}
                  ref={i === focusTile ? newTileRef : undefined}
                />
              </li>
            ))}
          </ul>
          {visibleJobs.length < filteredJobs.length && (
            <div className="DiscoveriesListing__loadMore">
              <p className="DiscoveriesListing__loadMoreInfo">
                {discoveriesListTranslations.jobsPagination
                  .replace("{0}", `${visibleJobs?.length || 0}`)
                  .replace("{1}", `${filteredJobs?.length || 0}`)}
              </p>
              <ButtonFill color="teal" onClick={handleShowMore}>
                {discoveriesListTranslations.showMoreJobs}
              </ButtonFill>
            </div>
          )}
        </>
      }
      sidePanel={
        <DiscoveriesList
          discoveries={discoveries}
          value={selectedDiscovery?.id || null}
          onSelect={filterJobs}
          translations={discoveriesListTranslations}
        />
      }
    />
  );
};
