import React from "react";
import classNames from "classnames";
import { lowerFirst } from "lodash";
import { IntroductionBlockDiscoveryViewModel } from "./IntroductionBlockDiscoveryViewModel.csharp";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";
import { Richtext } from "../../RichtextArea/Richtext";

interface IntroductionBlockDiscoveryProps extends IntroductionBlockDiscoveryViewModel {
  index: number;
  onThumbnailClick: (index: number) => void;
}

export const IntroductionBlockDiscovery = ({
  body,
  heading,
  layoutVariant,
  picture,
  pictureProfile,
  index,
  onThumbnailClick,
}: IntroductionBlockDiscoveryProps) => (
  <section
    className={classNames(
      "IntroductionBlockDiscovery",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      `IntroductionBlockDiscovery--${lowerFirst(layoutVariant)}`,
    )}
  >
    {picture.url && (
      <div className="IntroductionBlockDiscovery__imageSection">
        <div className="IntroductionBlockDiscovery__imageContainer">
          <button
            className="IntroductionBlockDiscovery__image"
            onClick={() => onThumbnailClick(index)}
            aria-label="Open in full screen gallery"
          >
            <ResponsivePicture model={picture} profile={pictureProfile} />
          </button>
        </div>
      </div>
    )}
    <div className="IntroductionBlockDiscovery__textSection">
      <h2 className="IntroductionBlockDiscovery__title">{heading}</h2>
      <Richtext className="IntroductionBlockDiscovery__body">{body}</Richtext>
    </div>
  </section>
);
