import React from "react";
import { ButtonOutlined } from "../../../react-components/Buttons/ButtonOutlined";
import { HamburgerClose } from "../../../react-components/Icons/HamburgerClose";
import { ResetFilters } from "../../../react-components/Buttons/ResetFilters/ResetFilters";
import { Count } from "../../../react-components/Atoms/Count/Count";

interface ProductsListingTopBarProps {
  filtersCount: number;
  onClearFilters: () => void;
  isMobile: boolean;
  showFiltersPanel: boolean;
  onToggleFiltersPanel: () => void;
  productsCount: number;
}

export const ProductsListingTopBar = ({
  filtersCount,
  onClearFilters,
  isMobile,
  showFiltersPanel,
  onToggleFiltersPanel,
  productsCount,
}: ProductsListingTopBarProps) => {
  if (isMobile) return null;

  return (
    <div className="ProductsListing__topBar">
      <div className="ProductsListing__topBarColumn">
        {filtersCount > 0 && <ResetFilters onClearFilters={onClearFilters} />}
      </div>
      <div className="ProductsListing__topBarColumn">
        <Count count={productsCount} label="products" variant="bold" />
        <ButtonOutlined
          className="ProductsListing__toggleFiltersButton"
          icon={showFiltersPanel ? HamburgerClose : undefined}
          iconPosition="right"
          onClick={onToggleFiltersPanel}
        >
          {showFiltersPanel ? "Close filters" : "Open filters"}
          <Count count={filtersCount} ariaLabel="Number of selected filters" variant="pill" />
        </ButtonOutlined>
      </div>
    </div>
  );
};
