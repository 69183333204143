import React, { FC, useState } from "react";
import { UrlHelper } from "../urlUtils";
import { TabSections } from "./TabSections/TabSections";
import { LinkDto } from "../../Microsite/LinkDto.csharp";

export interface TabSection {
  title: string;
  description?: string;
  items: LinkDto[];
}

export interface Tab {
  id: string;
  sectionId: string;
  label: string;
  sections: TabSection[];
  isCollapsibleOnMobile?: boolean;
}

export interface TabsListingProps {
  tabs: Tab[];
  displaySectionsAsList?: boolean;
}

export const TabsListing: FC<TabsListingProps> = ({ tabs, displaySectionsAsList = false }) => {
  const [chosenTab, setChosenSections] = useState<Tab>(tabs[0]);

  const handleTabClick = (clickedTab: Tab) => {
    setChosenSections(clickedTab);
    UrlHelper.removeHashFromUrl();
  };

  return (
    <div className="TabsListing">
      <div className="TabsListing__tabsWrapper">
        <div
          className="TabsListing__tabs"
          role="tablist"
          aria-orientation="horizontal"
          aria-label="Open vessel listing divided by segment name or by first letter of vessel name"
        >
          {tabs.map((t) => (
            <button
              key={t.id}
              id={t.id}
              className="TabsListing__tab"
              type="button"
              role="tab"
              aria-selected={t.id === chosenTab.id}
              aria-controls={t.sectionId}
              onClick={() => handleTabClick(t)}
            >
              <span>{t.label}</span>
            </button>
          ))}

          <span
            className="TabsListing__activeTabIndicator"
            aria-hidden
            style={{
              width: `${100 / (tabs.length ? tabs.length : 1)}%`,
              transform: `translateX(
              ${100 * tabs.indexOf(chosenTab)}%
            )`,
            }}
          />
        </div>
      </div>
      <section id={chosenTab.sectionId} aria-labelledby={chosenTab.id} role="tabpanel">
        <TabSections
          sections={chosenTab.sections}
          collapsibleOnMobile={chosenTab.isCollapsibleOnMobile}
          displayAsList={displaySectionsAsList}
        />
      </section>
    </div>
  );
};
