import React, { memo } from "react";
import { OfficeLocationsListItemDepartments } from "./OfficeLocationsListItemDepartments";
import { OfficeLocationDepartment, formatWebsiteUrl, getFullWebsiteUrl } from "./OfficeLocationsListItem.utils";
import { OfficeLocationsListItemProductsCategories } from "./OfficeLocationsListItemProductsCategories";
import { RelatedProductCategory } from "../../RelatedProductCategory.csharp";
import { Collapsible } from "../../../../react-components/Collapsible";
import { OfficeLocationsListItemLink } from "./OfficeLocationsListItemLink";
import { OfficeLocationsListItemSiteManager } from "./OfficeLocationsListItemSiteManager";
import { OfficeLocationsListWithMapListItemTranslations } from "../../OfficeLocationsListWithMapListItemTranslations.csharp";

interface OfficeLocationsListItemContactDetailsProps {
  isVisible?: boolean;
  websiteUrl: string;
  siteManager: string;
  departments: OfficeLocationDepartment[];
  categories: RelatedProductCategory[];
  translations: OfficeLocationsListWithMapListItemTranslations;
}

export const OfficeLocationsListItemContactDetails = memo<OfficeLocationsListItemContactDetailsProps>(function OfficeLocationsListItemContactDetails({
  isVisible = false,
  websiteUrl,
  siteManager,
  departments,
  categories,
  translations,
}) {
  return (
    <Collapsible
      className="OfficeLocationsListItem__contactDetails"
      isCollapsed={!isVisible}
    >
      <OfficeLocationsListItemLink
        href={getFullWebsiteUrl(websiteUrl)}
        isExternal
      >
        {formatWebsiteUrl(websiteUrl)}
      </OfficeLocationsListItemLink>

      <OfficeLocationsListItemSiteManager
        siteManager={siteManager}
        translations={translations}
      />

      <OfficeLocationsListItemDepartments
        departments={departments}
      />

      {categories.length > 0 && (
        <OfficeLocationsListItemProductsCategories
          categories={categories}
          translations={translations}
        />
      )}
    </Collapsible>
  );
});
