import React, { ChangeEventHandler, FC, FormEvent, KeyboardEvent, useState } from "react";
import classNames from "classnames";
import { Search } from "../../Icons/Search";

interface SearchFieldProps {
  value: string;
  onSubmit?: (event: FormEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  color?: "light" | "dark";
  searchButtonLabel?: string;
  placeholderText?: string;
  a11yHidden?: boolean;
  className?: string;
}

export const SearchField: FC<SearchFieldProps> = ({
  value,
  onSubmit,
  onChange,
  color = "black",
  searchButtonLabel,
  placeholderText,
  a11yHidden = false,
  className,
}) => {
  const [isFocusedByKeyboard, setIsFocusedByKeyboard] = useState(false);

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      setIsFocusedByKeyboard(true);
    }
  };

  return (
    <div className={classNames("SearchField", `SearchField--${color}`, className)}>
      <input
        className={classNames("SearchField__input", {
          "SearchField__input--hasKeyboardFocus": isFocusedByKeyboard,
        })}
        type="search"
        placeholder={placeholderText}
        value={value}
        onChange={onChange}
        tabIndex={a11yHidden ? -1 : undefined}
        onKeyUp={onKeyUp}
        onBlur={() => setIsFocusedByKeyboard(false)}
        onSubmit={onSubmit}
      />
      <button
        className="SearchField__button"
        type="submit"
        aria-label={searchButtonLabel || "Search"}
        tabIndex={a11yHidden ? -1 : undefined}
        onClick={onSubmit}
      >
        <Search fill="currentColor" className="SearchField__icon" />
      </button>
    </div>
  );
};
