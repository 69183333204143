import React, { forwardRef } from "react";
import { TileProps } from "../TileProps";
import { ResponsivePicture } from "../../../responsivePicture/responsivePicture";
import { LabelViewModel } from "../../../../Common/Labels/LabelViewModel.csharp";
import { Label } from "../../../Atoms/Label/Label";

interface SimpleTileProps extends TileProps {
  labels?: LabelViewModel[];
}

export const SimpleTile = forwardRef<HTMLAnchorElement, SimpleTileProps>(function SimpleTile(
  { href, picture, labels, title, description },
  ref,
) {
  return (
    <a className="SimpleTile" href={href} ref={ref}>
      {picture && (
        <div className="SimpleTile__imageWrapper">
          <ResponsivePicture profile={picture.profile} model={picture.model} />
        </div>
      )}
      {!!labels?.length && (
        <div className="SimpleTile__labels">
          {labels.map((label, idx) => (
            <Label key={idx} color={label.color}>
              {label.text}
            </Label>
          ))}
        </div>
      )}
      <h3 className="SimpleTile__name">{title}</h3>
      {description && <p className="SimpleTile__tagline">{description}</p>}
    </a>
  );
});
