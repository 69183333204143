//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DiscoveryThemeColor {
	Teal = 0,
	Blue = 1,
	Green = 2,
	Photo = 3,
	Brown = 4,
	Grey = 5,
	Success = 6,
	Alert = 7,
	Error = 8,
	AlertLighter = 9,
	AccentGreen = 10,
	AccentBlue = 11,
	AccentTeal = 12
}
