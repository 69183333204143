import React, { Fragment } from "react";
import { format } from "react-string-format";
import classNames from "classnames";
import { ResourceTile } from "./ResourceTile";
import { ButtonFill } from "../../react-components/Buttons/ButtonFill";
import { ResourcesViewModel } from "./ViewModels/ResourcesViewModel.csharp";
import { ResourceViewModel } from "./ViewModels/ResourceViewModel.csharp";
import { useIsKeyboardUsed } from "../../react-components/useIsKeyboardUsed";
import { useResources } from "./useResources";
import { ResourcesFilters } from "./ResourcesFilters";
import { ResourceTileSize } from "./ResourceTile.types";
import { UpcomingEvents } from "./UpcomingEvents";
import { MinimalisticArticleTeaser } from "../../Partials/ArticleTeaser/Minimalistic/MinimalisticArticleTeaser";

const NUMBER_OF_TILES_PER_PAGE = 14;

type ResourcesProps = ResourcesViewModel;

const skeletons = [...Array(NUMBER_OF_TILES_PER_PAGE).keys()];
const isBigSize = (index: number) => index % 7 === 0;
const getSize = (index: number): ResourceTileSize => (isBigSize(index) ? "big" : "medium");

export const Resources = ({
  title,
  introduction,
  pictureProfile,
  filters,
  resources,
  teaser,
  events,
  allEventsLink,
  getResourcesUrl,
  translations,
}: ResourcesProps) => {
  const isKeyboardUsed = useIsKeyboardUsed();
  const {
    isLoading,
    data,
    handleSubmit,
    selectedCategories,
    setSelectedCategories,
    selectedTopics,
    setSelectedTopics,
    showedItems,
    totalItems,
    handleLoadMore,
    isFetchingNextPage,
    hasNextPage,
  } = useResources({
    initialResources: resources,
    apiUrl: getResourcesUrl,
    categories: filters.category.optionViewModel,
    topics: filters.topic.optionViewModel,
  });

  const renderResource = (
    resource: ResourceViewModel | null,
    index: number,
    shouldFocus: boolean,
  ) => (
    <li
      key={index}
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      className={classNames("Resources__listItem", {
        "Resources__listItem--featured": isBigSize(index),
      })}
    >
      {resource ? (
        <ResourceTile
          size={getSize(index)}
          resource={resource}
          pictureProfile={pictureProfile}
          shouldFocusOnMount={shouldFocus}
        />
      ) : (
        <ResourceTile.Skeleton size={getSize(index)} />
      )}
    </li>
  );

  const renderResources = () => {
    const getShouldFocus = (pagesCount: number, pageIndex: number, resourceIndex: number) =>
      pagesCount > 1 && resourceIndex === 0 && pageIndex === pagesCount - 1 && isKeyboardUsed;

    return isLoading
      ? skeletons.map((_res, index) => renderResource(null, index, false))
      : data?.pages.map((page, pageIndex, pages) => (
          <Fragment key={page?.pageNumber}>
            {page?.items.map((res, i) =>
              renderResource(res, i, getShouldFocus(pages.length, pageIndex, i)),
            )}
          </Fragment>
        ));
  };

  const hasUpcomingEvents = !!events.length;
  const hasTeaser = !!teaser.href;
  const renderAside = hasUpcomingEvents || hasTeaser;

  return (
    <div className="Resources">
      <header className="Resources__header">
        <div className="Resources__headerInner">
          <h1 className="Resources__title">{title}</h1>
          {introduction && <p className="Resources__description">{introduction}</p>}
        </div>

        <ResourcesFilters
          handleSubmit={handleSubmit}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedTopics={selectedTopics}
          setSelectedTopics={setSelectedTopics}
          categoryFilters={filters.category}
          topicFilters={filters.topic}
        />
      </header>

      <div
        className={classNames("Resources__wrapper", {
          "Resources__wrapper--noResults": totalItems === 0,
        })}
      >
        <div
          className={classNames("Resources__wrapperInner", {
            "Resources__wrapperInner--asGrid": renderAside,
          })}
        >
          {totalItems > 0 ? (
            <section className="Resources__main">
              <h2
                className={classNames("Resources__subtitle", {
                  "Resources__subtitle--visuallyHidden": !renderAside,
                })}
              >
                {translations.latest}
              </h2>
              <div
                className={classNames("Resources__listingWrapper", {
                  "Resources__listingWrapper--withoutPaddingTopOnMobile": renderAside,
                })}
                itemScope
                itemType="https://schema.org/ItemList"
              >
                <ul className="Resources__list" aria-live="polite" aria-relevant="additions">
                  {renderResources()}
                </ul>

                <div className="Resources__pagination">
                  <p className="Resources__paginationCount">
                    {format(
                      translations.paginationCount,
                      showedItems,
                      <span itemProp="numberOfItems">{totalItems}</span>,
                    )}
                  </p>
                  <ButtonFill
                    color="teal"
                    onClick={() => void handleLoadMore()}
                    disabled={isFetchingNextPage || !hasNextPage}
                  >
                    {isFetchingNextPage ? translations.loading : translations.loadMore}
                  </ButtonFill>
                </div>
              </div>
            </section>
          ) : (
            <section className="Resources__noResults">
              <h2 className="Resources__subtitle Resources__subtitle--noResults">
                {translations.noResults.title}
              </h2>
              <p className="Resources__description Resources__description--noResults">
                {translations.noResults.description}
              </p>
            </section>
          )}
          {renderAside && (
            <aside className="Resources__aside">
              <UpcomingEvents items={events} seeAllLink={allEventsLink} />
              <MinimalisticArticleTeaser model={teaser} />
            </aside>
          )}
        </div>
      </div>
    </div>
  );
};
