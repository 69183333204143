//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProductState {
	New = 0,
	Available = 1,
	PhasedOut = 2,
	NoLongerSold = 3
}
