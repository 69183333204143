import React, { FC } from "react";
import { LinkBlockProps } from "../../Partials/LinkBlock/LinkBlock";
import { LinkBlockWithImage } from "../../Partials/LinkBlock/LinkBlockWithImage";

interface LinkBlockGridProps {
  items: LinkBlockProps[];
}

export const LinkBlockGrid: FC<LinkBlockGridProps> = ({ items }) => {
  return (
    <ul className="LinkBlockGrid">
      {items.map((item, idx) => {
        return (
          <li key={idx}>
            <LinkBlockWithImage model={item.model} disableFocus={item.disableFocus} />
          </li>
        );
      })}
    </ul>
  );
};
