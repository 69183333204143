import React, { FC } from "react";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import { Richtext } from "../RichtextArea/Richtext";
import { LinkBlockProps } from "./LinkBlock";
import { AssetHelper } from "../../react-components/assetHelper";

export const LinkBlockWithImage: FC<LinkBlockProps> = ({ model, disableFocus = false }) => {
  return (
    <div className="LinkBlock LinkBlock--withImage">
      <a href={model.linkUrl} tabIndex={disableFocus ? -1 : undefined}>
        <div className="LinkBlock__textContainer">
          <div className="LinkBlock__imageWrapper">
            {model.image && model.imageProfile ? (
              <ResponsivePicture model={model.image} profile={model.imageProfile} />
            ) : (
              <picture>
                <img src={AssetHelper.images.placeholder} alt="" />
              </picture>
            )}
          </div>
          <div className="LinkBlock__titleButton">
            {model.title ? <h3>{model.title}</h3> : <span>Button text</span>}
          </div>

          {model.description && (
            <div className="LinkBlock__text">
              <Richtext>{model.description}</Richtext>
            </div>
          )}
        </div>
      </a>
    </div>
  );
};
