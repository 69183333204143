import React from "react";
import { Richtext } from "../../Partials/RichtextArea/Richtext";
import { TabSections } from "../../react-components/TabsListing/TabSections/TabSections";
import { LinkBlockGrid } from "../../react-components/LinkBlockGrid/LinkBlockGrid";
import {
  filterOutVesselsNotIncludedInSegments,
  formatVesselsForPlainList,
  formatVesselsForSegments,
} from "../../react-components/vesselUtils";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { VesselSegmentPageProps } from "./VesselSegmentPageProps.csharp";

export const VesselSegmentPage = ({ model }: ComponentProps<VesselSegmentPageProps>) => {
  const { heading, intro, segments, vessels } = model;

  const hasSubSegments = segments && !!segments.length;
  const vesselSectionsSegments = hasSubSegments && formatVesselsForSegments(segments, vessels);
  const vesselList = formatVesselsForPlainList(vessels);
  const hasSectionSegmentsToShow = vesselSectionsSegments && vesselSectionsSegments.length;
  if (hasSectionSegmentsToShow) {
    const vesselsNotIncludedInSegments = filterOutVesselsNotIncludedInSegments(
      segments,
      vesselList,
    );
    if (vesselsNotIncludedInSegments.length) {
      vesselSectionsSegments.push({
        title: "Others",
        items: vesselsNotIncludedInSegments,
      });
    }
  }

  return (
    <>
      <section className="VesselSegmentPage__header">
        <h1 className="VesselSegmentPage__heading">{heading}</h1>
        {intro && <Richtext className="VesselSegmentPage__intro">{intro}</Richtext>}
      </section>
      <section className="VesselSegmentPage__content">
        {hasSectionSegmentsToShow && (
          <TabSections sections={vesselSectionsSegments} collapsibleOnMobile />
        )}
        {vesselList && !hasSectionSegmentsToShow && (
          <div className="VesselSegmentPage__plainVesselListWrapper">
            <LinkBlockGrid
              items={vesselList.map((item) => ({
                model: {
                  title: item.text,
                  description: item.description,
                  linkUrl: item.href,
                  image: item.image,
                  imageProfile: item.imageProfile,
                },
              }))}
            />
          </div>
        )}
      </section>
    </>
  );
};
