import React, { FC } from "react";
import { ComponentProps } from "../../ComponentProps.csharp";
import { MinimalisticArticleTeaserBlockViewModel } from "./MinimalisticArticleTeaserBlockViewModel.csharp";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";
import { ButtonGhost } from "../../../react-components/Buttons/ButtonGhost";
import { ArrowRight } from "../../../react-components/Icons/ArrowRight";
import classNames from "classnames";

export const MinimalisticArticleTeaser: FC<
  ComponentProps<MinimalisticArticleTeaserBlockViewModel>
> = ({ model }) => {
  if (!model.href) return null;

  return (
    <section
      className={classNames("MinimalisticArticleTeaser", {
        "MinimalisticArticleTeaser--noTitle": !model.heading,
      })}
    >
      {model.heading && (
        <>
          <h2 className="MinimalisticArticleTeaser__titleDesktop">{model.heading}</h2>
          {model.href && (
            <ButtonGhost
              mode="light"
              as="a"
              href={model.href}
              icon={ArrowRight}
              iconPosition="right"
              color="base"
              className="MinimalisticArticleTeaser__titleMobileButton"
            >
              <h2 className="MinimalisticArticleTeaser__titleMobile">{model.heading}</h2>
            </ButtonGhost>
          )}
        </>
      )}
      {model.href && model.image && (
        <a href={model.href} className="MinimalisticArticleTeaser__imageLink">
          <div className="MinimalisticArticleTeaser__imageWrapper">
            <ResponsivePicture {...model.image} />
          </div>
        </a>
      )}
    </section>
  );
};
