import { FormEvent, useRef } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  ResourceQueryFunctionContext,
  ResourcesAPIClient,
  ResourcesQueryKey,
} from "./ResourcesAPIClient";
import { PagingModel } from "../../Common/PagingModel.csharp";
import { ResourceViewModel } from "./ViewModels/ResourceViewModel.csharp";
import { ParamType, useSearchParams } from "../../react-components/useSearchParams";
import { useToast } from "../../react-components/Toast/useToast";
import { FilterOptionViewModel } from "./ViewModels/FilterOptionViewModel.csharp";
import { useErrorHandler } from "../../react-components/api/useErrorHandler";
import { RadioSelectOption } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { useCheckboxSelect } from "../../react-components/Inputs/hooks/useCheckboxSelect";
import { compareQueryKeys } from "../../react-components/api/utils";
import { queryClient } from "../../react-components/api/queryClient";

export enum ResourcesURLSearchParam {
  Category = "category",
  Topic = "topic",
  Query = "q",
}

interface ResourcesHookOptions {
  initialResources: PagingModel<ResourceViewModel>;
  apiUrl: string;
  categories: FilterOptionViewModel[];
  topics: FilterOptionViewModel[];
}

export const useResources = ({
  initialResources,
  apiUrl,
  categories,
  topics,
}: ResourcesHookOptions) => {
  const apiClient = new ResourcesAPIClient(apiUrl);

  const { error: toastError } = useToast();
  // const [searchValue, setSearchValue] = useState("");

  const [selectedCategories, setSelectedCategories] = useCheckboxSelect({
    onChange: handleCategoriesChange,
  });
  const [selectedTopics, setSelectedTopics] = useCheckboxSelect({ onChange: handleTopicChange });
  const initialQueryKeysRef = useRef<ResourcesQueryKey>([
    "resources",
    {
      selectedCategories: [],
      selectedTopics: [],
    },
  ]);

  const { setParams } = useSearchParams(
    {
      [ResourcesURLSearchParam.Query]: ParamType.String,
      [ResourcesURLSearchParam.Category]: ParamType.Array,
      [ResourcesURLSearchParam.Topic]: ParamType.Array,
    },
    ({ category, topic }) => {
      if (category?.length) {
        const searchCategories = category
          .map((category) => categories.find((option) => option.value === category))
          .filter((category): category is FilterOptionViewModel => !!category);

        setSelectedCategories(searchCategories);
        initialQueryKeysRef.current[1].selectedCategories = searchCategories.map(
          (category) => category.value,
        );
      }

      if (topic?.length) {
        const searchTopics = topic
          .map((topic) => topics.find((option) => option.value === topic))
          .filter((topic): topic is FilterOptionViewModel => !!topic);

        setSelectedTopics(searchTopics);
        initialQueryKeysRef.current[1].selectedTopics = searchTopics.map((topic) => topic.value);
      }
    },
  );

  // use function to avoid warning about using a variable before it is defined
  function handleCategoriesChange(newValue: RadioSelectOption[]) {
    setParams({ category: newValue.map(({ value }) => value) });
  }

  function handleTopicChange(newValue: RadioSelectOption[]) {
    setParams({ topic: newValue.map(({ value }) => value) });
  }

  const queryKey = [
    "resources",
    {
      selectedCategories: selectedCategories.map((category) => category.value),
      selectedTopics: selectedTopics.map((topic) => topic.value),
    },
  ] as ResourcesQueryKey;

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PagingModel<ResourceViewModel> | null,
    unknown,
    PagingModel<ResourceViewModel> | null,
    ResourcesQueryKey
  >({
    queryKey: queryKey,
    queryFn: (context: ResourceQueryFunctionContext) => apiClient.getResources(context),
    getNextPageParam: (lastPage) => (lastPage?.hasNext ? lastPage.pageNumber + 1 : undefined),
    initialData: () => {
      if (initialQueryKeysRef.current && compareQueryKeys(initialQueryKeysRef.current, queryKey)) {
        return {
          pages: [initialResources],
          pageParams: [],
        };
      }

      return undefined;
    },
    keepPreviousData: true,
  });

  useErrorHandler();

  const hasCachedData = !!queryClient.getQueryState(queryKey)?.data;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void refetch();
  };

  const handleLoadMore = async () => {
    const response = await fetchNextPage();

    if (response.isError) {
      toastError(
        "An error occurred while trying to fetch the data. Please try again later.",
        "resources-fetch-data-error",
      );
    }
  };

  const totalItems = data?.pages[data.pages.length - 1]?.totalItems ?? 0;
  const showedItems = data
    ? data.pages.reduce((accumulator, page) => {
        if (!page) return accumulator;

        return accumulator + page.items.length;
      }, 0)
    : 0;

  return {
    isLoading: !hasCachedData,
    data,
    handleSubmit,
    selectedCategories,
    setSelectedCategories,
    selectedTopics,
    setSelectedTopics,
    showedItems,
    totalItems,
    handleLoadMore,
    isFetchingNextPage,
    hasNextPage,
  };
};
