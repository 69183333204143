import React from "react";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { ProductBanner } from "../../react-components/ProductBanner/ProductBanner";
import { ProductState } from "../../../Models/Pages/ProductState.csharp";

const messages: {
  [key: string]: { title: string; description: string };
} = {
  New: {
    title: "New product!",
    description: "We have just added this product to our product portfolio.",
  },
  PhasedOut: {
    title: "Product is being phased out",
    description: "We will no longer sell this product and it is being phased out.",
  },
  NoLongerSold: {
    title: "Product no longer sold, but supported",
    description: "We will no longer sell this product and it is being phased out.",
  },
};

export const ProductPageStatus = ({ model, containerId }: ComponentProps<ProductState>) => {
  const message = messages[ProductState[model]];

  if (!message) return null;

  return (
    <ProductBanner
      title={message.title}
      description={message.description}
      containerId={containerId}
      color={model === ProductState.New ? "yellow" : "orange"}
    />
  );
};
