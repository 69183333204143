import React, { useEffect, useState } from "react";
import { FocusOn } from "react-focus-on";
import classNames from "classnames";
import { SelectOption } from "../SelectOption";
import { ButtonDropdown } from "../../Buttons/ButtonDropdown/ButtonDropdown";
import { CheckboxSelectDropdown } from "./CheckboxSelectDropdown";
import { CheckboxSelectValuesCountIndicator } from "./CheckboxSelectValuesCountIndicator";
import { useUniqueId } from "../../hooks/useUniqueId";
import { useSelectValues } from "../hooks/useSelectValues";

interface CheckboxSelectProps {
  label: string;
  name: string;
  options: SelectOption[];
  currentValues?: SelectOption[];
  onChange?: (newValues: SelectOption[]) => void;
  placeholder?: string;
  placeholderAsFirstOption?: boolean;
  className?: string;
}

export const CheckboxSelect = ({
  className,
  name,
  options: initialOptions,
  currentValues,
  onChange,
  placeholder,
  label,
  placeholderAsFirstOption,
}: CheckboxSelectProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const uniqueId = useUniqueId();

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const {
    savedOptions,
    setSavedOptions,
    values,
    setValues,
    saveOptions,
    clearOptions,
    changeOptions,
  } = useSelectValues({
    currentValues: currentValues ?? [],
    handleSave: (newValues) => {
      if (onChange) {
        onChange(newValues);
      }

      handleDropdownClose();
    },
  });

  const firstOption = { label: placeholder, value: "" } as SelectOption;
  const options = placeholderAsFirstOption ? [firstOption, ...initialOptions] : initialOptions;

  useEffect(() => {
    if (
      savedOptions.length === 0 &&
      values.length === 0 &&
      currentValues &&
      currentValues.length !== 0
    ) {
      setValues(currentValues);
      setSavedOptions(currentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  const htmlId = `CheckboxSelect${uniqueId}`;

  const renderDropdown = () => {
    if (!isDropdownOpen) return null;

    return (
      <CheckboxSelectDropdown
        id={`${htmlId}Dropdown`}
        name={name}
        values={values}
        options={options}
        handleChange={changeOptions}
        handleClear={clearOptions}
        handleSave={saveOptions}
      />
    );
  };

  return (
    <FocusOn
      enabled={isDropdownOpen}
      onEscapeKey={saveOptions}
      onClickOutside={saveOptions}
      scrollLock={false}
      noIsolation
      returnFocus
    >
      <div id={htmlId} className={classNames("CheckboxSelect", className)}>
        <ButtonDropdown
          isOpened={isDropdownOpen}
          onToggle={setDropdownOpen}
          buttonProps={{ "aria-controls": `${htmlId}Dropdown`, "aria-expanded": isDropdownOpen }}
        >
          <span id={`${htmlId}Label`}>{label}</span>
          <CheckboxSelectValuesCountIndicator selectedOptions={savedOptions?.length} />
        </ButtonDropdown>
        {renderDropdown()}
      </div>
    </FocusOn>
  );
};
