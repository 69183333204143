import React, { ComponentType, ReactNode, memo } from "react";
import classNames from "classnames";
import { ResponsivePicture } from "../../responsivePicture/responsivePicture";
import { ButtonFillProps } from "../../Buttons/ButtonFill";
import { ButtonOutlined, ButtonOutlinedProps } from "../../Buttons/ButtonOutlined";
import { ButtonGhostProps } from "../../Buttons/ButtonGhost";
import { Heading, HeadingLevel } from "../../Heading";
import { DiscoveryThemeColor } from "../../../Discovery/DiscoveryThemeColor.csharp";
import { lowerFirst } from "lodash";
import { ResponsivePictureProps } from "../../../Images/ResponsivePictureProps.csharp";
import { TileSize } from "../../../Discovery/TileSize.csharp";

export type TileColor = DiscoveryThemeColor;

export interface TileLink {
  url: string;
  label: string;
}

export type ButtonProps = ButtonFillProps<"a"> | ButtonOutlinedProps<"a"> | ButtonGhostProps<"a">;

export interface TileProps<T extends ButtonProps> {
  className?: string;
  headingLevel?: HeadingLevel;
  iconSvg?: string;
  picture?: ResponsivePictureProps | string;
  fallbackImageUrl?: string;
  alt?: string;
  title?: string;
  description?: string;
  size?: TileSize;
  color?: TileColor;
  href?: string;
  links?: TileLink[];
  buttonProps?: T;
  buttonComponent?: ComponentType<T>;
  renderButton?: ReactNode;
}

export const TILE_CLASS_NAME = 'Tile';
export const TILE_IMAGE_CLASS_NAME = 'Tile__image';

export const Tile = memo(function Tile<T extends ButtonProps>({
  className,
  headingLevel = 3,
  iconSvg,
  picture,
  fallbackImageUrl,
  alt = '',
  title,
  description,
  size = TileSize.Default,
  color = DiscoveryThemeColor.Photo,
  href,
  links,
  buttonProps,
  buttonComponent = ButtonOutlined,
  renderButton,
}: TileProps<T>) {
  const Tag = href ? 'a' : 'article';
  const ButtonComponent = buttonComponent;

  const hasButton = buttonProps || renderButton;
  const hasLinks = links && links.length > 0;

  const colorClassName = lowerFirst(
    Object.keys(DiscoveryThemeColor).filter((c) => isNaN(Number(c)))[color]
  );

  const tileClassNames = classNames(
    "Tile",
    {
      [`Tile--withImage`]: picture || iconSvg || fallbackImageUrl,
      [`Tile--${size.toLowerCase()}`]: size,
    },
    `Tile--${colorClassName}`,
    className,
  );

  const tileImageClassNames = classNames(
    "Tile__image",
    {
      "Tile__image--icon Icon": iconSvg,
    },
    {
      "Tile__image--fallbackImage": fallbackImageUrl && !picture,
    }
  );

  const tileContentClassNames = classNames(
    "Tile__content",
    {
      "Tile__content--withLinks": hasLinks || hasButton,
    }
  );

  const renderPictureFragment = () => {
    if (iconSvg) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: iconSvg }}
        />
      );
    }

    if (typeof picture === `string`) {
      return (
        <img src={picture} alt={alt} />
      );
    }

    if (picture) {
      return (
        <ResponsivePicture
          model={picture.model}
          profile={picture.profile}
        />
      );
    }

    if (fallbackImageUrl) {
      return (
        <img src={fallbackImageUrl} alt="" />
      );
    }

    return null;
  };

  const renderButtonFragment = () => {
    if (href) return null;

    if (renderButton) {
      return renderButton;
    }

    if (buttonProps && Object.keys(buttonProps).length) {
      const newButtonProps = { ...buttonProps };

      if (!newButtonProps.mode) {
        newButtonProps.mode = !picture && !iconSvg ? "dark" : "light";
      }

      return <ButtonComponent as="a" {...newButtonProps} />;
    }

    return null;
  };

  return (
    <Tag className={tileClassNames} href={href}>
      {(iconSvg || picture || fallbackImageUrl) && (
        <div className={tileImageClassNames}>
          {renderPictureFragment()}
        </div>
      )}

      {(title || description || hasLinks || hasButton) && (
        <div className={tileContentClassNames}>
          {(title || description) && (
            <div className="Tile__header">
              {title && (
                <Heading
                  level={headingLevel}
                  className="Tile__title"
                >
                  {title}
                </Heading>
              )}

              {description && (
                <p className="Tile__description">{description}</p>
              )}
            </div>
          )}

          {(hasLinks || hasButton) && (
            <div className="Tile__meta">
              {hasLinks && (
                <ul className="Tile__links">
                  {links.map((link, index) => (
                    <li key={index}>
                      <a href={link.url} className="Tile__link">{link.label}</a>
                    </li>
                  ))}
                </ul>
              )}

              {renderButtonFragment()}
            </div>
          )}
        </div>
      )}
    </Tag>
  );
});
