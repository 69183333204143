import React, { FC } from "react";
import { ContentListGroupListProps } from "./ContentListGroupListProps.csharp";
import { ComponentProps } from "../../ComponentProps.csharp";
import { SimpleTile } from "../../../react-components/TileGrid/Tiles/SimpleTile/SimpleTile";
import { Link } from "../../../react-components/Link/Link";
import { ExternalLinkIcon } from "../../../react-components/Discovery/Icons/ExternalLinkIcon";

export const GroupedContentList: FC<ComponentProps<ContentListGroupListProps>> = ({
  model: { items, pictureProfile, readMoreText },
}) => {
  return (
    <ul>
      {items.map((item) => {
        return (
          <li key={item.id} className="GroupedContentList__item">
            <SimpleTile
              picture={{ profile: pictureProfile, model: item.image }}
              href={item.href}
              labels={item.labels}
              title={item.name}
            />
            <h3 className="GroupedContentList__heading">{item.name}</h3>
            <p className="GroupedContentList__description">{item.description}</p>

            <Link
              title={readMoreText}
              icon={ExternalLinkIcon}
              iconPosition="right"
              href={item.href}
            />
          </li>
        );
      })}
    </ul>
  );
};
