import React, { FC } from "react";
import { ComponentProps } from "../ComponentProps.csharp";
import { LinkBlockViewModel } from "./LinkBlockViewModel.csharp";
import { Richtext } from "../RichtextArea/Richtext";

export type LinkBlockProps = ComponentProps<Partial<LinkBlockViewModel> & { linkUrl: string }> & {
  disableFocus?: boolean;
};

export const LinkBlock: FC<LinkBlockProps> = ({ model, disableFocus = false }) => {
  return (
    <div className="LinkBlock">
      <a href={model.linkUrl} tabIndex={disableFocus ? -1 : undefined}>
        <div className="LinkBlock__textContainer">
          {model.iconSvg && (
            <div className="LinkBlock__icon">
              <div dangerouslySetInnerHTML={{ __html: model.iconSvg }} />
            </div>
          )}

          {model.title && <h3 className="LinkBlock__title">{model.title}</h3>}

          {model.description && (
            <div className="LinkBlock__text">
              <Richtext>{model.description}</Richtext>
            </div>
          )}
        </div>

        {model.linkText && <span className="ButtonLink">{model.linkText}</span>}
      </a>
    </div>
  );
};
