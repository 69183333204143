import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ButtonFill } from "../../../../react-components/Buttons/ButtonFill";
import { Count } from "../../../../react-components/Atoms/Count/Count";
import { PictureProfileDto } from "../../../../Images/Resizing/PictureProfileDto.csharp";
import { ProductCardViewModel } from "../../../Product/ProductListingTile/ProductCardViewModel.csharp";
import { SimpleTile } from "../../../../react-components/TileGrid/Tiles/SimpleTile/SimpleTile";

const INITIAL_NUMBER_OF_VISIBLE_PRODUCTS = 9;
const LOAD_MORE_PRODUCTS_AMOUNT = 9;

interface ProductsListingProductsProps {
  products: ProductCardViewModel[];
  productCardPictureProfile: PictureProfileDto;
  fullWidth?: boolean;
  showNativeProductsCount?: boolean;
}

export const ProductsListingProducts = ({
  products,
  productCardPictureProfile,
  fullWidth = false,
  showNativeProductsCount = false,
}: ProductsListingProductsProps) => {
  const firstNewResultRef = useRef<HTMLAnchorElement>(null);
  const [visibleProductsNumber, setVisibleProductsNumber] = useState<number>(0);
  const [firstNewResultIndex, setFirstNewResultIndex] = useState<number>(0);

  useEffect(() => {
    setVisibleProductsNumber(Math.min(products.length, INITIAL_NUMBER_OF_VISIBLE_PRODUCTS));
  }, [products]);

  useEffect(() => {
    if (firstNewResultIndex === 0) return;
    firstNewResultRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleProductsNumber]);

  const onShowMoreClick = () => {
    setVisibleProductsNumber((prevState) => {
      setFirstNewResultIndex(prevState);
      const nextVisibleProductsAmount = prevState + LOAD_MORE_PRODUCTS_AMOUNT;
      return nextVisibleProductsAmount > products.length
        ? products.length
        : nextVisibleProductsAmount;
    });
  };

  const renderProducts = () => {
    return products.slice(0, visibleProductsNumber).map(({ href, name, tagline, image }, idx) => {
      const isFirstNewResult = idx === firstNewResultIndex;
      const picture = { model: image, profile: productCardPictureProfile };
      return (
        <SimpleTile
          key={idx}
          href={href}
          title={name}
          description={tagline}
          picture={picture}
          ref={isFirstNewResult ? firstNewResultRef : undefined}
        />
      );
    });
  };

  return (
    <div
      className={classNames("ProductsListingProducts", {
        [`ProductsListingProducts--fullWidth`]: fullWidth,
      })}
    >
      <div className="ProductsListingProducts__gridWrapper">
        {showNativeProductsCount && (
          <Count count={products.length} label="products" variant="bold" />
        )}
        <div className="ProductsListingProducts__grid">{renderProducts()}</div>
      </div>
      <div className="ProductsListingProducts__paginationWrapper">
        <span className="ProductsListingProducts__paginationText">
          Showed {visibleProductsNumber} of {products.length} products
        </span>
        {visibleProductsNumber < products.length && (
          <ButtonFill color="teal" onClick={onShowMoreClick}>
            Show more products
          </ButtonFill>
        )}
      </div>
    </div>
  );
};
