import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import { AssetHelper } from "../assetHelper";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";
import { PictureSourceDto } from "../../Images/Resizing/PictureSourceDto.csharp";
import { ScaleModeDto } from "../../Images/Resizing/ScaleModeDto.csharp";
import { ResizedImageFormatDto } from "../../Images/Resizing/ResizedImageFormatDto.csharp";
import { FocalPointDto } from "../../../Models/Media/Images/FocalPointDto.csharp";
import { ResponsivePictureViewModel } from "../../Images/ResponsivePictureViewModel.csharp";

export enum SourceType {
  PictureSource = "PictureSource",
  ScreenDensityPresetPictureSource = "ScreenDensityPresetPictureSource",
}

export function getResizedImageUrl(
  imageUrl: string,
  format: ResizedImageFormatDto | null = null,
  width: number | null = null,
  height: number | null = null,
  mode: ScaleModeDto | null = null,
  quality: number | null = null,
  focalPoint: FocalPointDto | null = null,
  screenDensity: number | null = null,
) {
  const urlParams = [];
  const extensionsToReplace: string[] = [".tiff", ".tif"];
  const extension: string = imageUrl.substring(imageUrl.lastIndexOf("."));

  if (width && width > 0) {
    urlParams.push(`width=${width.toString()}`);
  }

  if (height && height > 0) {
    urlParams.push(`height=${height.toString()}`);
  }

  if (mode) {
    const modeString = ScaleModeDto[mode];
    urlParams.push(`rmode=${modeString}`);
  }

  if (quality) {
    urlParams.push(`quality=${quality.toString()}`);
  }

  if (focalPoint) {
    urlParams.push(`rxy=${focalPoint.x},${focalPoint.y}`);
  }

  if (
    (format === null || format === ResizedImageFormatDto.Preserve) &&
    extensionsToReplace.includes(extension)
  ) {
    urlParams.push(`format=${ResizedImageFormatDto[1]}`);
  }

  if (format) {
    urlParams.push(`format=${ResizedImageFormatDto[format]}`);
  }

  const url = urlParams.length > 0 ? imageUrl + "?" + urlParams.join("&") : imageUrl;
  return screenDensity ? `${url} ${screenDensity}x` : url;
}

interface ResponsivePictureState {
  isError: boolean;
}

export class ResponsivePicture extends React.Component<
  ResponsivePictureProps & ComponentPropsWithoutRef<"img">,
  ResponsivePictureState
> {
  private screenDensities = [1, 2, 3];

  constructor(props: ResponsivePictureProps) {
    super(props);

    this.state = {
      isError: false,
    };

    this.createSourceElement = this.createSourceElement.bind(this);
  }

  public componentDidUpdate(prevProps: ResponsivePictureProps) {
    if (prevProps.model.url !== this.props.model.url) {
      this.setState({
        isError: false,
      });
    }
  }

  public render(): ReactNode {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { model, profile, containerId, ...rest } = this.props;

    if (!model) return null;

    return (
      <picture>
        {!this.state.isError ? this.renderImage(model, rest) : this.renderPlaceholder(rest)}
      </picture>
    );
  }

  private renderImage(
    model: ResponsivePictureViewModel,
    additionalProps: ComponentPropsWithoutRef<"img">,
  ) {
    const sources = this.buildSources();

    return (
      <>
        {sources}
        <img
          alt={model ? model.alt : ""}
          src={this.getDefaultUrl()}
          onError={() => {
            this.setState({ isError: true });
          }}
          {...additionalProps}
        />
      </>
    );
  }

  private renderPlaceholder(additionalProps: ComponentPropsWithoutRef<"img">) {
    return <img alt="" src={AssetHelper.images.placeholder} {...additionalProps} />;
  }

  private buildSources(): ReactNode[] {
    if (!this.props.profile || !this.props.profile.sources) {
      return [];
    }

    return this.props.profile.sources.map(this.createSourceElement);
  }

  private createSourceElement(pictureSource: PictureSourceDto) {
    let srcSet = "";

    if ((pictureSource.sourceType as SourceType) === SourceType.ScreenDensityPresetPictureSource) {
      srcSet = this.screenDensities
        .map((density) =>
          this.buildResizedImageUrl(
            this.getImageUrl(),
            pictureSource,
            this.props.profile.format,
            this.props.model.responsiveImageData?.focalPoint,
            density,
          ),
        )
        .join(", ");
    } else {
      srcSet = this.buildResizedImageUrl(
        this.getImageUrl(),
        pictureSource,
        this.props.profile.format,
        this.props.model.responsiveImageData?.focalPoint,
        null,
      );
    }

    return (
      <source
        key={pictureSource.mediaCondition}
        media={pictureSource.mediaCondition}
        srcSet={srcSet}
        onError={() => this.setState({ isError: true })}
      />
    );
  }

  private buildResizedImageUrl(
    imageUrl: string,
    source: PictureSourceDto,
    // eslint-disable-next-line default-param-last
    format: number | null = 0,
    focalPoint: FocalPointDto | null,
    screenDensity: number | null,
  ): string {
    if (!source) return imageUrl;

    return getResizedImageUrl(
      imageUrl,
      format,
      screenDensity ? source.width * screenDensity : source.width,
      screenDensity ? source.height * screenDensity : source.height,
      source.mode,
      source.quality,
      focalPoint,
      screenDensity,
    );
  }

  private getDefaultUrl(): string {
    return getResizedImageUrl(
      this.getImageUrl(),
      this.props.profile.format,
      this.props.profile.defaultWidth,
    );
  }

  private getImageUrl(): string {
    return this.props.model.url || AssetHelper.images.placeholder || "";
  }
}
