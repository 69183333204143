import React, { FC } from "react";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { ArrowRight } from "../../react-components/Icons/ArrowRight";
import { ComponentProps } from "../ComponentProps.csharp";
import { ContentListTileViewModel } from "../ContentList/Tiles/ContentListTileViewModel.csharp";

export interface InfoRevealTileProps extends ComponentProps<ContentListTileViewModel> {
  renderImageAsBg?: boolean;
}

export const InfoRevealTile: FC<InfoRevealTileProps> = ({ model, renderImageAsBg }) => {
  const { title, description, url, image } = model;
  const { url: imageUrl } = image.model;

  const renderContent = () => (
    <>
      {renderImageAsBg && (
        <img className="InfoRevealTile__image" src={imageUrl} alt="" aria-hidden />
      )}
      <div className="InfoRevealTile__content">
        <div className="InfoRevealTile__textWrapper InfoRevealTile__textWrapper--default">
          {title && (
            <div className="InfoRevealTile__title" aria-hidden>
              {title}
            </div>
          )}
        </div>
        <div className="InfoRevealTile__textWrapper InfoRevealTile__textWrapper--active">
          {title && <h3 className="InfoRevealTile__title">{title}</h3>}
          {description && <p className="InfoRevealTile__description">{description}</p>}
          {url && (
            <ButtonGhost
              type="icon"
              color="blue"
              icon={ArrowRight}
              mode="dark"
              as="div"
              className="InfoRevealTile__iconBtn"
            />
          )}
        </div>
      </div>
    </>
  );

  return url ? (
    <a className="InfoRevealTile" href={url}>
      {renderContent()}
    </a>
  ) : (
    <div className="InfoRevealTile">{renderContent()}</div>
  );
};
