import React, { FC, useState } from "react";
import { AgendaViewModel } from "./AgendaViewModel.csharp";
import { ComponentProps } from "../ComponentProps.csharp";
import { formatDate } from "../../react-components/dateUtil";
import { Tabs } from "../../react-components/Tabs/Tabs";
import { AgendaDay } from "./AgendaDay";

const dateFormatSingleEvent = "D MMMM, YYYY";
const dateFormatMultipleMobile = "D MMM";
const dateFormatMultipleDesktop = "D MMMM";

const defaultChildrenCount = 5;

export const Agenda: FC<ComponentProps<AgendaViewModel>> = ({ model }) => {
  const isSingleDayEvent = model.days.length === 1;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [visibleAgendaItems, setVisibleAgendaItems] = useState(defaultChildrenCount);

  return (
    <section className="Section Section--verticalPaddings Section--fullWidth Section--light-tint">
      <div className="wrapper">
        <h2 className="Agenda__header">{model.heading}</h2>
        <Tabs>
          {{
            labels: model.days.map((day, index) => (
              <Tabs.Label
                key={day.dayString}
                idString={day.dayString}
                isSingleTab={isSingleDayEvent}
                isActive={activeTabIndex === index}
                onClick={() => setActiveTabIndex(index)}
              >
                <span className="hideOnDesktop">
                  {formatDate(
                    day.dayString,
                    "en",
                    isSingleDayEvent ? dateFormatSingleEvent : dateFormatMultipleMobile,
                  )}
                </span>
                <span className="hideOnMobile">
                  {formatDate(
                    day.dayString,
                    "en",
                    isSingleDayEvent ? dateFormatSingleEvent : dateFormatMultipleDesktop,
                  )}
                </span>
              </Tabs.Label>
            )),
            tabs: model.days.map((day, index) => (
              <Tabs.Tab
                isActive={activeTabIndex === index}
                key={day.dayString}
                idString={day.dayString}
                showMoreItemsButton={{
                  showMoreItemsLabel: model.showMoreLabel,
                  showMoreItems: () => setVisibleAgendaItems(visibleAgendaItems + 5),
                  allItemsShown: visibleAgendaItems <= day.items.length,
                }}
              >
                <AgendaDay
                  items={day.items}
                  visibleItemsCount={visibleAgendaItems}
                  speakerLabel={model.speakerLabel}
                  readMoreLabel={model.readMoreLabel}
                  readLessLabel={model.readLessLabel}
                />
              </Tabs.Tab>
            )),
          }}
        </Tabs>
      </div>
    </section>
  );
};
